import { TransactionPatternAnalysisV1 } from "../_EnterpriseCommon.model";
import { commonService_Update_ChoiceWithComment } from "./EnterpriseCommon.service";

export const transactionPatternService_updateFromTo = (
  status: TransactionPatternAnalysisV1,
  payload: { dateFrom: string; dateTo: string | undefined },
): TransactionPatternAnalysisV1 => {
  let result: TransactionPatternAnalysisV1;
  result = {
    ...status,
    dateFrom: payload.dateFrom,
    dateTo: payload.dateTo,
  };
  return result;
};
export const transactionPatternService_updateComment = (
  status: TransactionPatternAnalysisV1,
  payload: {
    comment2: string;
    comment3: string;
    comment4: string;
    comment5: string | undefined;
  },
): TransactionPatternAnalysisV1 => {
  let result: TransactionPatternAnalysisV1;
  result = {
    ...status,
    comment2: payload.comment2,
    comment3: payload.comment3,
    comment4: payload.comment4,
    comment5: payload.comment5,
  };
  return result;
};
