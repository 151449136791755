import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { RootState } from "./AppStoreRegistryStore";
import {
  AssessmentStateV1,
  CustomerAssessmentV1,
} from "../_domain-model/CustomerAssessment.model";

import {
  cloneAssessment,
  serviceCleanSavedAssessment,
  serviceInitAssessment,
} from "../_domain-model/services/CustomerAssessmentFactory.service";
import {
  eventService_updateAlarmEventField,
  eventServiceUpdate_WhenSelectionChanges,
  serviceIsAlarmFound,
} from "../_domain-model/services/AlamEventStatus.service";
import {
  multiUnitService_updateMultiUnit,
  multiUnitService_updateOtherBusiness,
} from "../_domain-model/services/02MultiUnit.service";
import { serviceGetValidAssessmentType } from "../_domain-model/services/AssessmentType.service";
import {
  serviceGetValidRiskType,
  serviceIsHighRisk,
} from "../_domain-model/services/RiskType.service";
import {
  commonService_Update_ChoiceWithComment,
  commonService_Update_IdentificationMethod,
  commonService_UpdateChanges_LastAssessment,
  commonService_UpdateRisk_LastAssessment,
} from "../_domain-model/services/EnterpriseCommon.service";
import {
  purposeService_updateIdentification,
  purposeService_updateComment,
  purposeService_updateRisk,
  purposeService_updateTpaComment,
  purposeService_updateChanges,
  purposeService_updateCustomerPurposeRelationshipComment,
  purposeService_updateOriginWealthsComment,
} from "../_domain-model/services/04Purpose.service";
import {
  originFundsService_updateChanges,
  originFundsService_updateComment,
  originFundsService_updateIdentification,
  originFundsService_updateRisk,
} from "../_domain-model/services/05OriginFunds.service";
import {
  ownershipService_updateChanges,
  ownershipService_updateComment,
  ownershipService_updateIdentification,
} from "../_domain-model/services/06Ownership.service";
import { beneficiaryService_updateComment } from "../_domain-model/services/07Beneficiary.service";
import {
  pepService_updateChoiceComment,
  pepService_updateConfirmation,
  pepService_updateDeclarationDate,
  pepService_updateIdentification,
} from "../_domain-model/services/08Pep.service";
import {
  mediaService_updateChoiceComment,
  mediaService_updateDate,
} from "../_domain-model/services/10Media.service";
import {
  extraInfoService_updateCanEstablish,
  extraInfoService_updateChoiceComment,
  extraInfoService_updateComment,
  extraInfoService_updateTiltakComment,
} from "../_domain-model/services/11ExtraInfo.service";
import {
  extraConclusion_updateComment,
  extraConclusion_updateTiltakComment,
} from "../_domain-model/services/12Conclusion.service";
import { arrayIsEmpty } from "../Utils/ArrayUtils";
import { PersonWithRoleV1 } from "../_domain-model/_Common.model";
import {
  customerInfoService_updateChanges,
  customerInfoService_updateIdentification,
  customerInfoService_updateRisk,
} from "../_domain-model/services/01CustomerInfo.service";
import {
  representativesService_updateChanges,
  representativesService_updateIdentification,
  representativesService_updateRisk,
} from "../_domain-model/services/03Representatives.service";
import { international_transaction_updateChoiceComment } from "../_domain-model/services/InternationalTransaction.service";
import { high_risk_updateChoiceComment } from "../_domain-model/services/HighRisk.service";
import { sanction_updateChoiceComment } from "../_domain-model/services/Sanction.service";
import {
  transactionPatternService_updateComment,
  transactionPatternService_updateFromTo,
} from "../_domain-model/services/TransactionPatternAnalysis.service";

const initialState: AssessmentStateV1 = {
  assessmentType: undefined,
  riskType: undefined,
  isHighRisk: undefined,
  locked: false,
  signatures: [],
  assessment: undefined,
};

const init = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | null | undefined,
) => {
  if (!state.assessment) {
    console.log("init assessment");
    state.assessment = serviceInitAssessment();
  }
};

const reset = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | null | undefined,
) => {
  console.log("reset assessment");
  state.assessmentType = undefined;
  state.riskType = undefined;
  state.isHighRisk = undefined;
  state.locked = false;
  state.signatures = [];
  state.assessment = serviceInitAssessment();
};

const initAssessmentFromState = (
  state: WritableDraft<AssessmentStateV1>,
  payload: AssessmentStateV1 | null | undefined,
) => {
  console.log(payload);
  let cleaned = serviceCleanSavedAssessment(payload);

  if (!cleaned) {
    console.log("nothing in saved-state");
    return;
  }
  console.log("init assessment from state ");
  state.assessmentType = cleaned.assessmentType;
  state.riskType = cleaned.riskType;
  state.isHighRisk = cleaned.isHighRisk;
  state.locked = cleaned.locked;
  state.signatures = cleaned.signatures;
  state.assessment = cleaned.assessment;
};

const replaceAssessment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: CustomerAssessmentV1 | null | undefined,
) => {
  if (!payload || payload === null) {
    return;
  }
  console.log("replacing assessment");
  console.log(cloneAssessment(payload));
  state.assessment = cloneAssessment(payload);
};

const changeAssessmentType = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | null | undefined,
) => {
  state.assessmentType = serviceGetValidAssessmentType(payload);
};

const changeRiskType = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | null | undefined,
) => {
  state.riskType = serviceGetValidRiskType(payload);
  state.isHighRisk = serviceIsHighRisk(payload);
};

// signatures
const addNewSignatory = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string,
) => {
  const id = "sign_" + new Date().getMilliseconds();
  let p: PersonWithRoleV1 = {
    id: id,
  };
  let people = [...state.signatures];
  people.push(p);
  state.signatures = people;
};

const removeSignatory = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string,
) => {
  if (arrayIsEmpty(state.signatures)) {
    return;
  }
  let people: PersonWithRoleV1[] = [];
  const signs = state.signatures;
  signs.forEach((p) => {
    if (p.id !== payload) {
      people.push(p);
    }
  });

  state.signatures = people;
};

// alarm events
const confirmAlarmEvent = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | null | undefined,
) => {
  let foundAlarm = serviceIsAlarmFound(payload);
  if (state.assessment) {
    state.assessment.eventStatus.foundAlarm = foundAlarm.found;
    state.assessment.eventStatus.alarmConfirmation = foundAlarm.confirmation;
  }
};

const updateSelectedAlarmEvents = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string[] | null | undefined,
) => {
  let status = eventServiceUpdate_WhenSelectionChanges(
    payload,
    state.assessment.eventStatus,
  );
  console.log(status);
  state.assessment.eventStatus = status;
};

const updateAlarmEventField = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { fieldName: string; alarmCode: string; value: string | undefined },
) => {
  let status = eventService_updateAlarmEventField(
    state.assessment.eventStatus,
    payload,
  );
  // console.log(status);
  state.assessment.eventStatus = status;
};

const updateMultiUnit = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = multiUnitService_updateMultiUnit(
    state.assessment.multiUnitCustomer,
    payload,
  );
  state.assessment.multiUnitCustomer = status;
};

const updateMultiUnitOtherBusiness = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = multiUnitService_updateOtherBusiness(
    state.assessment.multiUnitCustomer,
    payload,
  );
  state.assessment.multiUnitCustomer = status;
};

// preliminary remark
const updatePreliminaryRemark = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = commonService_Update_ChoiceWithComment(
    state.assessment?.remark?.choice,
    payload,
  );
  state.assessment.remark.choice = status;
};

// customer-info
const updateCustomerInfoIdentification = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = customerInfoService_updateIdentification(
    state.assessment?.customerInfo,
    payload,
  );
  state.assessment.customerInfo = status;
};

const updateCustomerInfoChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = customerInfoService_updateChanges(
    state.assessment?.customerInfo,
    payload,
  );
  state.assessment.customerInfo = status;
};

const updateCustomerInfoRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = customerInfoService_updateRisk(
    state.assessment?.customerInfo,
    payload,
  );
  state.assessment.customerInfo = status;
};

// representanter
const updateRepresentativesChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = representativesService_updateChanges(
    state.assessment?.representatives,
    payload,
  );
  state.assessment.representatives = status;
};

const updateRepresentativesConfirmed = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined },
) => {
  const rr = {
    ...state.assessment.representatives,
  };
  if (arrayIsEmpty(payload.choices)) {
    rr.changes.confirmed = undefined;
  } else {
    rr.changes.confirmed = payload.choices[0];
  }
  state.assessment.representatives = rr;
};

const updateRepresentativesRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = representativesService_updateRisk(
    state.assessment?.representatives,
    payload,
  );
  state.assessment.representatives = status;
};

const updateRepresentativesIdMethod = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = representativesService_updateIdentification(
    state.assessment?.representatives,
    payload,
  );
  state.assessment.representatives = status;
};

// formål - purpose
const updatePurposeCustomerRelationshipComment2 = (
  state: WritableDraft<AssessmentStateV1>,
  payload: {
    customerPurposeComment: string | undefined;
  },
) => {
  let status = purposeService_updateCustomerPurposeRelationshipComment(
    state.assessment?.purpose,
    payload,
  );
  state.assessment.purpose = status;
};
const updatePurposeIdMethod = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = purposeService_updateIdentification(
    state.assessment?.purpose,
    payload,
  );
  state.assessment.purpose = status;
};

const updatePurposeChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = purposeService_updateChanges(state.assessment?.purpose, payload);
  state.assessment.purpose = status;
};

const updatePurposeRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = purposeService_updateRisk(state.assessment?.purpose, payload);
  state.assessment.purpose = status;
};

const updatePurposeComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = purposeService_updateComment(state.assessment.purpose, payload);
  state.assessment.purpose = status;
};

const updatePurposeTpaComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = purposeService_updateTpaComment(
    state.assessment.purpose,
    payload,
  );
  state.assessment.purpose = status;
};

// midlerens opprinnelse
const updateOriginFundsIdMethod = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = originFundsService_updateIdentification(
    state.assessment?.originOfFunds,
    payload,
  );
  state.assessment.originOfFunds = status;
};

const updateOriginFundsChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = originFundsService_updateChanges(
    state.assessment?.originOfFunds,
    payload,
  );
  state.assessment.originOfFunds = status;
};

const updateOriginFundsRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = originFundsService_updateRisk(
    state.assessment?.originOfFunds,
    payload,
  );
  state.assessment.originOfFunds = status;
};

const updateOriginFundsComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = originFundsService_updateComment(
    state.assessment.originOfFunds,
    payload,
  );
  state.assessment.originOfFunds = status;
};

// eierskap
const updateOwnershipIdMethod = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = ownershipService_updateIdentification(
    state.assessment?.ownership,
    payload,
  );
  state.assessment.ownership = status;
};

const updateOwnershipChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = ownershipService_updateChanges(
    state.assessment?.ownership,
    payload,
  );
  state.assessment.ownership = status;
};

const updateOwnershipRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = commonService_UpdateRisk_LastAssessment(
    state.assessment?.ownership?.changes,
    payload,
  );
  state.assessment.ownership.changes = status;
};

const updateOwnershipComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = ownershipService_updateComment(
    state.assessment.ownership,
    payload,
  );
  state.assessment.ownership = status;
};

// reelle rettighetshavere
const updateBeneficiaryIdMethod = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = commonService_Update_IdentificationMethod(
    state.assessment?.beneficiary?.identificationMethod,
    payload,
  );
  state.assessment.beneficiary.identificationMethod = status;
};

const updateBeneficiaryZenit = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = commonService_Update_IdentificationMethod(
    state.assessment?.beneficiary?.zenitIdentification,
    payload,
  );
  state.assessment.beneficiary.zenitIdentification = status;
};

const updateBeneficiaryChanges = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = commonService_UpdateChanges_LastAssessment(
    state.assessment?.beneficiary?.changes,
    payload,
  );
  state.assessment.beneficiary.changes = status;
};

const updateBeneficiaryRisk = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = commonService_UpdateRisk_LastAssessment(
    state.assessment?.beneficiary?.changes,
    payload,
  );
  state.assessment.beneficiary.changes = status;
};

const updateBeneficiaryComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = beneficiaryService_updateComment(
    state.assessment.beneficiary,
    payload,
  );
  state.assessment.beneficiary = status;
};

// pep
const updatePepChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = pepService_updateChoiceComment(state.assessment?.pep, payload);
  state.assessment.pep = status;
};
const updatePepDeclaration = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = pepService_updateDeclarationDate(state.assessment?.pep, payload);
  state.assessment.pep = status;
};
const updatePepInfoIdentification = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined; comment: string | undefined },
) => {
  let status = pepService_updateIdentification(state.assessment?.pep, payload);
  state.assessment.pep = status;
};
const updatePepConfirmation = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choices: string[] | undefined },
) => {
  let status = pepService_updateConfirmation(state.assessment?.pep, payload);
  state.assessment.pep = status;
};

// media
const updateMediaChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = mediaService_updateChoiceComment(
    state.assessment?.media,
    payload,
  );
  state.assessment.media = status;
};
const updateMediaDate = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = mediaService_updateDate(state.assessment?.media, payload);
  state.assessment.media = status;
};
// extra-info
const updateExtraInfoChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = extraInfoService_updateChoiceComment(
    state.assessment?.extraInfo,
    payload,
  );
  state.assessment.extraInfo = status;
};
const updateExtraInfoTiltak = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = extraInfoService_updateTiltakComment(
    state.assessment?.extraInfo,
    payload,
  );
  state.assessment.extraInfo = status;
};
const updateCanEstablish = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = extraInfoService_updateCanEstablish(
    state.assessment?.extraInfo,
    payload,
  );
  state.assessment.extraInfo = status;
};
const updateExtraInfoComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = extraInfoService_updateComment(
    state.assessment?.extraInfo,
    payload,
  );
  state.assessment.extraInfo = status;
};

// MT-melding
const updateMTMessage = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = commonService_Update_ChoiceWithComment(
    state.assessment?.mtMessage?.choice,
    payload,
  );
  state.assessment.mtMessage.choice = status;
};

// conclusion
const updateConclusionTiltak = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = extraConclusion_updateTiltakComment(
    state.assessment?.conclusion,
    payload,
  );
  state.assessment.conclusion = status;
};
const updateConclusionComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: string | undefined,
) => {
  let status = extraConclusion_updateComment(
    state.assessment?.conclusion,
    payload,
  );
  state.assessment.conclusion = status;
};
// international
const updateInternationalTransactionChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = international_transaction_updateChoiceComment(
    state.assessment?.internationalTransaction,
    payload,
  );
  state.assessment.internationalTransaction = status;
};
// high risk
const updateHighRiskChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = high_risk_updateChoiceComment(
    state.assessment?.highRisk,
    payload,
  );
  state.assessment.highRisk = status;
};
// sanction
const updateSanctionChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { choice: string; comment: string | undefined },
) => {
  let status = sanction_updateChoiceComment(
    state.assessment?.sanction,
    payload,
  );
  state.assessment.sanction = status;
};
// wealth
const updateOriginWealthsComment = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { comment: string | undefined },
) => {
  let status = purposeService_updateOriginWealthsComment(
    state.assessment?.originOfWealth,
    payload.comment,
  );
  state.assessment.originOfWealth = status;
};
// transaction pattern
const updateTransactionPatternDateChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: { dateFrom: string; dateTo: string | undefined },
) => {
  let status = transactionPatternService_updateFromTo(
    state.assessment?.transactionPatternAnalysis,
    payload,
  );
  state.assessment.transactionPatternAnalysis = status;
};
const updateTransactionPatternCommentChoice = (
  state: WritableDraft<AssessmentStateV1>,
  payload: {
    comment2: string;
    comment3: string;
    comment4: string;
    comment5: string | undefined;
  },
) => {
  let status = transactionPatternService_updateComment(
    state.assessment?.transactionPatternAnalysis,
    payload,
  );
  state.assessment.transactionPatternAnalysis = status;
};

export const CustomerAssessmentStore = createSlice({
  name: "CustomerAssessmentStore",
  initialState,
  reducers: {
    initAssessmentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      init(state, action.payload);
    },
    resetAssessmentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      reset(state, action.payload);
    },
    initAssessmentFromStateAction: (
      state,
      action: PayloadAction<AssessmentStateV1 | null | undefined>,
    ) => {
      initAssessmentFromState(state, action.payload);
    },
    unlockAssessmentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.locked = false;
    },
    replaceAssessmentAction: (
      state,
      action: PayloadAction<CustomerAssessmentV1 | null | undefined>,
    ) => {
      replaceAssessment(state, action.payload);
    },
    lockAssessmentAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.locked = true;
    },
    changeAssessmentTypeAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      changeAssessmentType(state, action.payload);
    },
    changeRiskTypeAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      changeRiskType(state, action.payload);
    },
    // signatures
    // add new signature
    addNewSignatoryAction: (state, action: PayloadAction<string>) => {
      addNewSignatory(state, action.payload);
    },
    removeSignatoryAction: (state, action: PayloadAction<string>) => {
      removeSignatory(state, action.payload);
    },
    // alarm-events
    confirmAlarmEventAction: (
      state,
      action: PayloadAction<string | null | undefined>,
    ) => {
      confirmAlarmEvent(state, action.payload);
    },
    updateSelectedAlarmEventsAction: (
      state,
      action: PayloadAction<string[] | null | undefined>,
    ) => {
      updateSelectedAlarmEvents(state, action.payload);
    },
    updateAlarmEventFieldAction: (
      state,
      action: PayloadAction<{
        fieldName: string;
        alarmCode: string;
        value: string | undefined;
      }>,
    ) => {
      updateAlarmEventField(state, action.payload);
    },
    // preliminary-remark
    updatePreliminaryRemarkAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updatePreliminaryRemark(state, action.payload);
    },
    // customer-info
    updateCustomerInfoIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateCustomerInfoIdentification(state, action.payload);
    },
    updateCustomerInfoChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateCustomerInfoChanges(state, action.payload);
    },
    updateCustomerInfoRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateCustomerInfoRisk(state, action.payload);
    },
    // updatePurposeCommentAction: (state, action: PayloadAction<string | undefined>) => {
    //     updatePurposeComment(state, action.payload);
    // },
    // konsern-felleskunde
    updateMultiUnitAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateMultiUnit(state, action.payload);
    },
    updateMultiUnitOtherBusinessAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateMultiUnitOtherBusiness(state, action.payload);
    },
    // represantenter
    updateRepresentativesChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateRepresentativesChanges(state, action.payload);
    },
    updateRepresentativesConfirmedAction: (
      state,
      action: PayloadAction<{ choices: string[] }>,
    ) => {
      updateRepresentativesConfirmed(state, action.payload);
    },
    updateRepresentativesRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateRepresentativesRisk(state, action.payload);
    },
    updateRepresentativesIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateRepresentativesIdMethod(state, action.payload);
    },
    // formål
    updatePurposeCustomerRelationshipCommentAction: (
      state,
      action: PayloadAction<{
        customerPurposeComment: string | undefined;
      }>,
    ) => {
      updatePurposeCustomerRelationshipComment2(state, action.payload);
    },
    updatePurposeIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updatePurposeIdMethod(state, action.payload);
    },
    updatePurposeChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updatePurposeChanges(state, action.payload);
    },
    updatePurposeRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updatePurposeRisk(state, action.payload);
    },
    updatePurposeCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updatePurposeComment(state, action.payload);
    },
    updatePurposeTpaCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updatePurposeTpaComment(state, action.payload);
    },
    // Midlenes opprinnelse
    updateOriginFundsIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateOriginFundsIdMethod(state, action.payload);
    },
    updateOriginFundsChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateOriginFundsChanges(state, action.payload);
    },
    updateOriginFundsRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateOriginFundsRisk(state, action.payload);
    },
    updateOriginFundsCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateOriginFundsComment(state, action.payload);
    },
    // eierskap
    updateOwnershipIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateOwnershipIdMethod(state, action.payload);
    },
    updateOwnershipChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateOwnershipChanges(state, action.payload);
    },
    updateOwnershipRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateOwnershipRisk(state, action.payload);
    },
    updateOwnershipCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateOwnershipComment(state, action.payload);
    },
    // reelle rettighetshavere
    updateBeneficiaryIdMethodAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateBeneficiaryIdMethod(state, action.payload);
    },
    updateBeneficiaryZenitAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updateBeneficiaryZenit(state, action.payload);
    },
    updateBeneficiaryChangesAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateBeneficiaryChanges(state, action.payload);
    },
    updateBeneficiaryRiskAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateBeneficiaryRisk(state, action.payload);
    },
    updateBeneficiaryCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateBeneficiaryComment(state, action.payload);
    },
    // pep
    updatePepChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updatePepChoice(state, action.payload);
    },
    updatePepDeclarationAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updatePepDeclaration(state, action.payload);
    },
    updatePepInfoIdentificationAction: (
      state,
      action: PayloadAction<{
        choices: string[] | undefined;
        comment: string | undefined;
      }>,
    ) => {
      updatePepInfoIdentification(state, action.payload);
    },
    updatePepConfirmationAction: (
      state,
      action: PayloadAction<{ choices: string[] | undefined }>,
    ) => {
      updatePepConfirmation(state, action.payload);
    },
    // media
    updateMediaChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateMediaChoice(state, action.payload);
    },
    updateMediaDateAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateMediaDate(state, action.payload);
    },
    // extra-info
    updateExtraInfoChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateExtraInfoChoice(state, action.payload);
    },
    updateExtraInfoTiltakAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateExtraInfoTiltak(state, action.payload);
    },
    updateCanEstablishAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateCanEstablish(state, action.payload);
    },
    updateExtraInfoCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateExtraInfoComment(state, action.payload);
    },
    // mt-melding
    updateMTMessageAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateMTMessage(state, action.payload);
    },
    // conclusion
    updateConclusionTiltakAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateConclusionTiltak(state, action.payload);
    },
    updateConclusionCommentAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      updateConclusionComment(state, action.payload);
    },
    // international
    updateInternationalTransactionChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateInternationalTransactionChoice(state, action.payload);
    },
    // high risk
    updateHighRiskChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string | undefined }>,
    ) => {
      updateHighRiskChoice(state, action.payload);
    },
    // sanction
    updateSanctionChoiceAction: (
      state,
      action: PayloadAction<{ choice: string; comment: string }>,
    ) => {
      updateSanctionChoice(state, action.payload);
    },

    updateOriginWealthsCommentAction: (
      state,
      action: PayloadAction<{ comment: string }>,
    ) => {
      updateOriginWealthsComment(state, action.payload);
    },
    // transaction pattern
    updateTransactionPatternDateAction: (
      state,
      action: PayloadAction<{ dateFrom: string; dateTo: string | undefined }>,
    ) => {
      updateTransactionPatternDateChoice(state, action.payload);
    },
    updateTransactionPatternCommentAction: (
      state,
      action: PayloadAction<{
        comment2: string;
        comment3: string;
        comment4: string;
        comment5: string | undefined;
      }>,
    ) => {
      updateTransactionPatternCommentChoice(state, action.payload);
    },
  },
});

export const {
  initAssessmentAction,
  resetAssessmentAction,
  initAssessmentFromStateAction,
  unlockAssessmentAction,
  replaceAssessmentAction,
  lockAssessmentAction,
  changeAssessmentTypeAction,
  changeRiskTypeAction,
  // signatures
  addNewSignatoryAction,
  removeSignatoryAction,
  // alarm-events
  confirmAlarmEventAction,
  updateSelectedAlarmEventsAction,
  updateAlarmEventFieldAction,
  updatePreliminaryRemarkAction,
  // customer-info
  updateCustomerInfoIdMethodAction,
  updateCustomerInfoChangesAction,
  updateCustomerInfoRiskAction,
  // konsern-felles
  updateMultiUnitAction,
  updateMultiUnitOtherBusinessAction,
  // representanter
  updateRepresentativesChangesAction,
  updateRepresentativesConfirmedAction,
  updateRepresentativesRiskAction,
  updateRepresentativesIdMethodAction,
  // purpose
  updatePurposeIdMethodAction,
  updatePurposeChangesAction,
  updatePurposeRiskAction,
  updatePurposeCommentAction,
  updatePurposeTpaCommentAction,
  updatePurposeCustomerRelationshipCommentAction,
  // Midlenes opprinnelse
  updateOriginFundsIdMethodAction,
  updateOriginFundsChangesAction,
  updateOriginFundsRiskAction,
  updateOriginFundsCommentAction,
  // eierskap
  updateOwnershipIdMethodAction,
  updateOwnershipChangesAction,
  updateOwnershipRiskAction,
  updateOwnershipCommentAction,
  // reelle rettighetshavere
  updateBeneficiaryIdMethodAction,
  updateBeneficiaryZenitAction,
  updateBeneficiaryChangesAction,
  updateBeneficiaryRiskAction,
  updateBeneficiaryCommentAction,
  // pep
  updatePepChoiceAction,
  updatePepDeclarationAction,
  updatePepInfoIdentificationAction,
  updatePepConfirmationAction,
  // media
  updateMediaChoiceAction,
  updateMediaDateAction,
  // extra-info
  updateExtraInfoChoiceAction,
  updateExtraInfoTiltakAction,
  updateCanEstablishAction,
  updateExtraInfoCommentAction,
  // mt-melding
  updateMTMessageAction,
  // conclusion
  updateConclusionTiltakAction,
  updateConclusionCommentAction,
  // international
  updateInternationalTransactionChoiceAction,
  // high risk
  updateHighRiskChoiceAction,
  // sanction
  updateSanctionChoiceAction,
  // wealth
  updateOriginWealthsCommentAction,
  // transaction pattern ana
  updateTransactionPatternDateAction,
  updateTransactionPatternCommentAction,
} = CustomerAssessmentStore.actions;

export const selectAssessmentFormState = (state: RootState) =>
  state.CustomerAssessmentStore;
export const selectAssessmentTypeState = (state: RootState) =>
  state.CustomerAssessmentStore.assessmentType;
export const selectRiskTypeState = (state: RootState) =>
  state.CustomerAssessmentStore.riskType;
export const selectIsHighRiskState = (state: RootState) =>
  state.CustomerAssessmentStore.isHighRisk;
export const selectAssessmentLockedState = (state: RootState) =>
  state.CustomerAssessmentStore.locked;
export const selectAssessmentState = (state: RootState) =>
  state.CustomerAssessmentStore.assessment;
export const selectSignaturesState = (state: RootState) =>
  state.CustomerAssessmentStore.signatures;

export default CustomerAssessmentStore.reducer;
